import { Link } from 'gatsby';
import React from 'react';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hoverAndActiveColor, primaryColor } from 'helpers/themehelpers';
import { mq } from 'helpers/stylehelpers';
import { colors, fontSizes } from 'helpers/variables';
import { isPartiallyActive } from 'helpers/utils';

import Icon from './icon';

/* Theming */

const transitionSpeed = '0.25s';

/** Styles für Links, deshalb ausgelagert weil für active benötigt */
const linkStyles = css`
    border-bottom: 2px solid transparent;
    color: ${colors.textColor};
    cursor: pointer;
    font-size: 1em;
    position: relative;
    text-decoration: none;
    transition: color ${transitionSpeed}, border-color ${transitionSpeed};
    z-index: 2;

    &:hover {
        color: ${hoverAndActiveColor};
        &:not(.navlink--is-active) {
            text-decoration: none;
        }
        &:not(.navlink--has-submenu) {
            border-bottom: 2px solid ${hoverAndActiveColor};
        }
    }

    .submenu__item & {
        &,
        &:hover {
            font-size: 0.9em;
            border-bottom: none !important;
        }
    }
`;

const InnerWrapper = styled.div`
    ${mq.mediumDown`
        width: ${rem(300)};
        overflow: auto;
    `};
`;

/** Nav */
const Wrapper = styled.nav`
    flex: 0 0 auto;

    ${mq.mediumDown`
        overflow-x: hidden;
        height: 100vh;
        position: absolute;
        background-color: ${colors.white};
        right: 0;
        top: 0;
        width: ${rem(0)};
        transition: transform 0.5s, box-shadow 0.5s, width 0.5s;
    `};

    ${({ offCanvasActive }) =>
        /* Off-Canvas aktivieren */
        offCanvasActive &&
        css`
            ${mq.mediumDown`
                width: ${rem(300)};
                transform: translateX(0);
                box-shadow: 0px 0px 15px ${rgba(colors.black, 0.25)};
            `};
        `};

    /* Navigationspunkt aktivieren */
    .navlink--is-active {
        ${linkStyles};
        color: ${hoverAndActiveColor};
        border-bottom: 2px solid ${hoverAndActiveColor};
    }

    /* Border-Bottom für Navpunkte innerhalb der Subnav deaktivieren */
    .submenu__item .navlink--is-active {
        ${linkStyles};
        font-size: 0.9em;
        color: ${hoverAndActiveColor};
        border-bottom: none !important;
        text-decoration: underline;
    }
`;

/** NavList */
const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    ${mq.mediumDown`
        &:not(.submenu) {
            height: 100%;
            overflow-y: auto;
            padding: 6em 0 1em;
        }
    `};

    ${mq.large`
        display: flex;
    `};
`;

/** Nav Link */
const StyledLink = styled(Link)`
    ${linkStyles};
`;

/** Icon für Elemente mit Submenüs */
const StyledIcon = styled(Icon)`
    ${mq.mediumDown`
        display: none !important;
    `};
    font-size: 0.85em;
    transition: transform ${transitionSpeed};
`;

/** Nav Item */
const Item = styled.li`
    position: relative;

    ${mq.mediumDown`
        &:not(.submenu__item) {
            border-bottom: 1px solid ${colors.gray300};
            margin-bottom: 0.75em;
            padding: 0 1.5em 0.75em;
        }
        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
            margin-bottom: 0.75em;
        }
    `};

    ${mq.large`
        ${fontSizes.s};
        margin-right: 1.5em;
    `};

    ${mq.xlarge`
        ${fontSizes.m};
        margin-right: 1.5em;
    `};

    ${mq.xxlarge`
        margin-right: 2.5em;
    `};

    ${mq.xxxlarge`
        margin-right: 3em;
    `};

    /* Abstand nach rechts für Submenüs und letztes Item auf 1. Ebene ausschalten */
    .submenu > li,
    &:last-child {
        margin-right: 0;
    }

    /* Styling für Submenüs */
    /* -------------------- */

    /* Wenn Subnavi an erstem Element in der horizontalen Reihe hängt */
    &:first-child {
        .submenu {
            ${mq.large`
                left: 0;
                transform: translate(0, 0.5em);
            `};
        }
    }

    /* Wenn Subnavi an letztem Element in der horizontalen Reihe hängt */
    &:last-child {
        .submenu {
            ${mq.large`
                left: auto;
                right: 0;
                transform: translate(0, 0.5em);
            `};
        }
    }

    /* Das Submenü (List) innerhalb eines Navigationsitems */
    .submenu {
        ${mq.mediumDown`
            margin: 0.25em 0 0 0.5em;
        `};

        ${mq.large`
            background: ${colors.white};
            border-top: 2px solid ${primaryColor};
            box-shadow: 0px 0px 4px ${rgba(colors.black, 0.25)};
            display: block;
            left: 50%;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            text-align: center;
            transform: translate(-50%, 0.5em);
            transition: opacity ${transitionSpeed}, transform ${transitionSpeed};
            width: ${rem(220)};
            z-index: 1;
        `};
    }

    /** Das einzelne Item innerhalb eines (Haupt)-Navigationsitems */
    .submenu__item {
        padding: 0.35em 0.65em;

        ${mq.mediumDown`
            &:last-child {
                padding-bottom: 0;
            }
        `};

        ${mq.large`
            padding: 0.5em 0.65em;
        `};

        &:not(:last-child) {
            ${mq.large`
                border-bottom: 1px solid ${colors.gray300};
            `};
        }
    }

    /* Bei Mouse-Over Submenüs anzeigen */
    &:hover {
        ${mq.large`
            .submenu {
                opacity: 1;
                pointer-events: all;
                transform: translate(-50%, 0);
            }

            // Unterstreichungslinie bei Elemente mit Subnavigationen entfernen
            .navlink--has-submenu.navlink--is-active {
                border-color: transparent;
            }

            > ${StyledLink}, ${StyledIcon} {
                color: ${hoverAndActiveColor};
            }

            ${StyledIcon} {
                transform: scale(1, -1) translateY(-1px);
            }

            &:first-child .submenu,
            &:last-child .submenu {
                transform: translate(0, 0);
            }
        `};
    }
`;

/**
 * Überprüft auf Basis des aktuellen Pathnames ob die Items die eine Subnav haben
 * aktiv geschalten werden müssen
 * @param {string} pagetitle Der Seitenname
 * @param {string} pathname Aktuelle Seitenpfad (relativ)
 */
const checkParentPath = (pagetitle, pathname) => {
    const title = pagetitle
        .toLowerCase()
        .replace(/\s/g, '')
        .replace('&', '-und-');
    return pathname.substring(1).startsWith(title) ? 'navlink--is-active' : '';
};

/**
 * Rekursiver Navigationsbaum
 * @param {string} props.className Klassenname (optional)
 * @param {object} props.items Navigations(sub)-Struktur
 */
const Tree = ({ className, items }) => (
    <List className={className}>
        {items.map(item => (
            <Item key={item.title} className={className === 'submenu' ? 'submenu__item' : ''}>
                {!item.subpages ? (
                    <StyledLink to={item.url} getProps={isPartiallyActive}>
                        {item.title}
                    </StyledLink>
                ) : (
                    <Location>
                        {({ location: { pathname } }) => (
                            <StyledLink
                                to={item.url}
                                as="span"
                                className={`${checkParentPath(
                                    item.title,
                                    pathname
                                )} navlink--has-submenu`}
                            >
                                {item.title} <StyledIcon type="caretDown" />
                            </StyledLink>
                        )}
                    </Location>
                )}
                {item.subpages ? <Tree items={item.subpages} className="submenu" /> : null}
            </Item>
        ))}
    </List>
);

Tree.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
            children: PropTypes.array,
        })
    ).isRequired,
};

Tree.defaultProps = {
    className: null,
};

/**
 * Navigations-Komponente
 * @param {Object} props.navStructure Die Struktur der Navigation
 * @param {Boolean} props.offCanvasActive Flag, ob Navigation aktiv ist
 */
const Navigation = ({ pageStructure, offCanvasActive, pathname }) => (
    <Wrapper offCanvasActive={offCanvasActive}>
        <InnerWrapper>
            <Tree items={pageStructure} pathname={pathname} />
        </InnerWrapper>
    </Wrapper>
);

Navigation.propTypes = {
    offCanvasActive: PropTypes.bool,
    pageStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
    pathname: PropTypes.string,
};

Navigation.defaultProps = {
    offCanvasActive: false,
    pathname: null,
};

export default Navigation;
